'use client';

// import { Input, SegmentedControl, Stack } from '@mantine/core'
// import { useContext } from 'react'

// import { OrderBy, ProfileOrderBy } from '~/generated/graphql'

// import { AccountsFilterContext } from '../AccountsFilterContext'
// import { mobileSortControl } from './styles.css'
export function SearchFilterMobileSort() {
  // const { order, orderDirection, setOrderDirection, setOrder } = useContext(
  //   AccountsFilterContext,
  // )

  return null;
  // <Stack gap={'xl'} p="lg" my="lg">
  //   <Input.Wrapper size="md" label="Sort By">
  //     <SegmentedControl
  //       name="sortBy"
  //       fullWidth
  //       value={order}
  //       color="brand"
  //       onChange={(v) => setOrder(v as keyof ProfileOrderBy)}
  //       data={[
  //         { label: 'Last Name', value: 'lastName' },
  //         { label: 'Brand', value: 'brand' },
  //       ]}
  //       classNames={{
  //         label: mobileSortControl,
  //       }}
  //     />
  //   </Input.Wrapper>
  //   <Input.Wrapper size="md" label="Direction">
  //     <SegmentedControl
  //       name="direction"
  //       fullWidth
  //       value={orderDirection}
  //       color="brand"
  //       onChange={(v) => {
  //         setOrderDirection(v as OrderBy)
  //       }}
  //       data={[
  //         { label: 'Ascending', value: OrderBy.Asc },
  //         { label: 'Descending', value: OrderBy.Desc },
  //       ]}
  //       classNames={{
  //         label: mobileSortControl,
  //       }}
  //     />
  //   </Input.Wrapper>
  // </Stack>
}